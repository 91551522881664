
// Libraries
import * as React from 'react'

// Components
import Layout from '../components/layout'
import Seo from '../components/seo'

const NotFoundPage = () => (
	<Layout className="nav-blue-all">
    	<Seo title="404: Page Not Found" />
    	<section className="not-found">
			<div className="container">
				<h1 className="not-found__heading">404</h1>
				<h4 className="not-found__subheading">Page Not Found</h4>
			</div>
		</section>
  	</Layout>
)

export default NotFoundPage
